import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { formatCurrency } from '@app/utils/format/currency';
import { useFlagVariation } from '@app/contexts/FwfFlags/hooks';
import { hasPercentage, hasFreeTrial } from '@commons/utils/markdowns';
import { DISCOUNT_TYPE } from '@commons/models/discountTypeEnum';
import { BOOLEAN_FLAGS } from '@commons/fwf/constants';
import RowInformativeL from '@pedidosya/web-fenix/system/RowInformativeL';
import { Payments } from '../Payments/Payments';
import messages from '../../../../status/messages';

const getMonthText = (stringDate) => {
  const date = new Date(stringDate);
  const month = date.toLocaleString('es-ES', { month: 'long' });
  return month.charAt(0).toUpperCase() + month.slice(1);
};

const getFullYear = (stringDate) => {
  const date = new Date(stringDate);
  return date.getFullYear();
};

const getDiscountText = (amount, discountType) => {
  switch (discountType) {
    case DISCOUNT_TYPE.PERCENTAGE:
      return `${amount}%`;
    default:
      amount;
  }
};

export const PlanDetail = ({ subscription, purchase, onShowPayments = Function.prototype }) => {
  const billFlag = useFlagVariation(BOOLEAN_FLAGS.WEB_LOYALTY_PROGRAM_BILLS.KEY);
  const { plan_id, start_date, currency_symbol, monthly_fee } = subscription;
  const { markdowns } = purchase;
  const [first] = markdowns || [];
  const { discount_amount, discount_type, campaign_period_valid_to, monthly_fee_after_markdown } =
    first || {};

  const intl = useIntl();
  const freeTrialMarkdown = hasFreeTrial(markdowns);
  const percentageMarkdowns = hasPercentage(markdowns);
  const actualAmount = markdowns ? monthly_fee_after_markdown : monthly_fee;
  const beforeAmount = markdowns ? monthly_fee : null;

  const [showPayments, setShowPayments] = useState(false);

  const getDiscountTitle = () => {
    const discount = percentageMarkdowns
      ? intl.formatMessage(messages.planDiscountTitle, {
          discountAmountText: getDiscountText(Math.floor(discount_amount), discount_type),
        })
      : null;
    return freeTrialMarkdown ? 'Gratis' : discount;
  };

  const getSubtitle = () => {
    return markdowns
      ? intl.formatMessage(messages.promotionSubtitle, {
          date: intl.formatDate(campaign_period_valid_to),
        })
      : intl.formatMessage(messages.planSubtitle, {
          month: getMonthText(start_date),
          year: getFullYear(start_date),
        });
  };

  const onPaymentsClick = () => {
    setShowPayments(true);
    onShowPayments();
  };

  return (
    <>
      <RowInformativeL
        primaryLeftText={intl.formatMessage(messages.planTitle)}
        secondaryLeftText={getSubtitle()}
        primaryRightText={formatCurrency(intl, {
          amount: actualAmount,
          currencySymbol: currency_symbol,
        })}
        tag={getDiscountTitle()}
        tagType="deals-and-discounts"
        discount={
          beforeAmount
            ? formatCurrency(intl, { amount: beforeAmount, currencySymbol: currency_symbol })
            : null
        }
        {...(billFlag && {
          textLink: intl.formatMessage(messages.paymentsHistory),
          linkOnClick: onPaymentsClick,
        })}
      />

      <Payments planId={plan_id} open={showPayments} onClose={() => setShowPayments(false)} />
    </>
  );
};

PlanDetail.propTypes = {
  subscription: PropTypes.shape({
    next_charge_date: PropTypes.string,
    status: PropTypes.string,
    start_date: PropTypes.string,
    currency_symbol: PropTypes.string,
  }),
  purchase: PropTypes.shape({
    markdowns: PropTypes.array,
    amount: PropTypes.number,
  }),
};
