import * as React from 'react';
import { useIntl } from 'react-intl';
import { isPercentage, isFreeTrial } from '@commons/utils/markdowns';
import getPaymentMethodLogo from '@app/utils/images/paymentMethodLogo';
import { DISCOUNT_TYPE } from '@commons/models/discountTypeEnum';
import { useMutation } from '@tanstack/react-query';
import { formatDateToDayMonth } from '@commons/utils/date/date';
import { getUnitIdFromValue } from '@commons/models/durationUnitEnum';
import differenceInCalendarDays from '@commons/utils/date/differenceInCalendarDays';
import { reactivateSubscription } from '@app/services/loyalty/reactivateSubscription';
import { TRACKING_CLICK_LOCATIONS } from '@commons/constants/tracker';

import FenixBottomSheet from '@pedidosya/web-fenix/system/BottomSheet';
import RowInformativeM from '@pedidosya/web-fenix/system/RowInformativeM';
import RowInformativeL from '@pedidosya/web-fenix/system/RowInformativeL';
import ImageContainerPayment from '@pedidosya/web-fenix/system/ImageContainerPayment';
import Footer from '@pedidosya/web-fenix/system/Footer';
import FooterButton from '@pedidosya/web-fenix/system/FooterButton';
import Snackbar from '@pedidosya/web-fenix/system/Snackbar';

import messages from '../../../messages';
import landingMessage from '../../../../landing/components/plan/messages';

const paymentMethodTypes = {
  CREDIT: 'Crédito',
  DEBIT: 'Débito',
};

const getDiscountText = (amount, discountType) => {
  if (discountType === DISCOUNT_TYPE.PERCENTAGE) {
    return `${amount}%`;
  } else {
    return amount;
  }
};

const BUTTON_LABEL = 'Reactivar suscripción';

function ReactivateSubscription({ open, onClose, subscription, onSubscriptionUpdate, trackClick }) {
  const [isOpenReactivateMessage, setIsOpenReactivateMessage] = React.useState(false);
  const [reactivateMessageType, setReactivateMessageType] = React.useState('positive');
  const intl = useIntl();

  const { currency_symbol, monthly_fee, purchase, next_charge_date, markdown } = subscription;
  const { brand, last_digits, type } = purchase;
  const {
    discount_amount,
    discount_type,
    duration_value,
    duration_unit,
    for_reactivation,
    monthly_fee_after_markdown,
  } = markdown || {};

  const freeTrialMarkdown = isFreeTrial(markdown);
  const percentageMarkdowns = isPercentage(markdown);

  const icon = getPaymentMethodLogo(brand);

  const getDiscount = (discount) => {
    if (freeTrialMarkdown) {
      if (for_reactivation) {
        return '1 mes gratis';
      } else return 'Gratis';
    } else return discount;
  };

  const getPlanAmount = () => {
    const actualAmount = markdown ? monthly_fee_after_markdown : monthly_fee;
    const beforeAmount = markdown ? monthly_fee : null;
    const discount = getDiscount(
      percentageMarkdowns
        ? intl.formatMessage(messages.planDiscountTitle, {
            discountAmountText: getDiscountText(Math.floor(discount_amount), discount_type),
          })
        : null,
    );

    return { currency_symbol, actualAmount, beforeAmount, discount };
  };

  const getSubtitleMessage = () => {
    if (!markdown) return intl.formatMessage(messages.reactivateSubscriptionSubtitle);
    if (!for_reactivation)
      return intl.formatMessage(messages.reactivateSubscriptionSubtitleMarkdown, {
        remainingDate: formatDateToDayMonth(next_charge_date),
      });
    return freeTrialMarkdown
      ? intl.formatMessage(messages.reactivateSubscriptionSubtitleMarkdownNewFree, {
          duration: intl.formatMessage(landingMessage.durationText, {
            value: duration_value,
            unit: getUnitIdFromValue(duration_unit),
          }),
        })
      : intl.formatMessage(messages.reactivateSubscriptionSubtitleMarkdownNewPerc, {
          duration: intl.formatMessage(landingMessage.durationText, {
            value: duration_value,
            unit: getUnitIdFromValue(duration_unit),
          }),
          percentage: discount_amount,
        });
  };

  const remainingDays = differenceInCalendarDays(new Date(next_charge_date), new Date(Date.now()));

  const mutation = useMutation({
    mutationFn: () => reactivateSubscription(),
    onSuccess: () => {
      onSubscriptionUpdate();
      setReactivateMessageType('positive');
      setIsOpenReactivateMessage(true);
    },
    onError: () => {
      setReactivateMessageType('error');
      setIsOpenReactivateMessage(true);
    },
  });

  const onReactivateSubs = () => {
    trackClick(TRACKING_CLICK_LOCATIONS.REACTIVATE_SUBSCRIPTION);
    mutation.mutate();
    onClose();
  };

  const title = intl.formatMessage(messages.reactivateSubscription);
  const subtitle = getSubtitleMessage();
  const planTitle = intl.formatMessage(messages.planTitle);
  const planPrice = getPlanAmount();
  const upcomingPaymentDate = intl.formatMessage(messages.activeSubtitle, {
    isFirst: false,
    remainingDays,
  });
  const snackBarLabel =
    reactivateMessageType == 'positive'
      ? intl.formatMessage(messages.reactivateSubscriptionPositiveMessage)
      : intl.formatMessage(messages.reactivateSubscriptionErrorMessage);

  return (
    <>
      <FenixBottomSheet
        handler={false}
        open={open}
        onClickOut={onClose}
        onClose={onClose}
        title={title}
      >
        <RowInformativeM
          paddingBottom="spacing-component-medium"
          paddingTop="spacing-component-medium"
          primaryText={subtitle}
          primaryTextType="default"
        />

        <RowInformativeM
          amount={`${planPrice?.currency_symbol} ${planPrice?.actualAmount}`}
          amountType="default"
          discount={planPrice?.beforeAmount}
          paddingBottom="spacing-component-medium"
          paddingTop="spacing-component-medium"
          primaryText={planTitle}
          primaryTextType="highlight"
          tag={planPrice?.discount}
          tagType="deals-and-discounts"
        />

        <RowInformativeL
          primaryLeftText={`${type} **** ${last_digits}`}
          // El BANK NAME es un dato que BE no está mandando. Hay que solicitarlo (quedo fuera de la estimacion original)
          // label="acá iria el BANK_NAME"
          secondaryLeftText={upcomingPaymentDate}
        >
          <ImageContainerPayment
            src={icon}
            variant="default"
            size="xsmall"
            imageAlt="Tarjeta Bancaria"
          />
        </RowInformativeL>

        <Footer
          footerType={
            <FooterButton>
              <FooterButton.Button
                onClick={() => onReactivateSubs()}
                fullWidth
                hierarchy="primary"
                label={BUTTON_LABEL}
                size="large"
              />
            </FooterButton>
          }
          spacingComponent={null}
        />
      </FenixBottomSheet>
      <Snackbar
        position="bottom-center"
        label={snackBarLabel}
        onClose={() => setIsOpenReactivateMessage(false)}
        show={isOpenReactivateMessage}
        type={reactivateMessageType}
      />
    </>
  );
}

export default ReactivateSubscription;
