import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';

import RowInformativeM from '@pedidosya/web-fenix/system/RowInformativeM';
import RowInformativeS from '@pedidosya/web-fenix/system/RowInformativeS';

function BenefitConditions({ bullets, title }) {
  return (
    <>
      <RowInformativeM primaryText={title} primaryTextType="highlight" />
      <ul aria-label="conditions-bullet">
        {bullets.map((bullet, index) => {
          const bulletContent = `${'\u00A0'.repeat(1)} · ${'\u00A0'.repeat(3)}${
            DOMPurify.sanitize(bullet, { RETURN_DOM: true }).textContent
          }`;

          return (
            <li key={index}>
              <RowInformativeS primaryText={bulletContent} />
            </li>
          );
        })}
      </ul>
    </>
  );
}

BenefitConditions.propTypes = {
  content: PropTypes.node,
  bullets: PropTypes.arrayOf(PropTypes.string),
};

export default BenefitConditions;
