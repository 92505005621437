import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import FenixHeader from '@pedidosya/web-fenix/system/Header';
import { HeaderIOS } from './styled-components';
import messages from '../../../messages';

export const Header = ({ onClickGoBack }) => {
  const isWebviewIos = useIsWebviewIos();
  const intl = useIntl();
  return (
    <>
      <HeaderIOS isWebviewIos={isWebviewIos} data-testid="detail-page-revamp" />
      <FenixHeader>
        <FenixHeader.TopBar
          left="icon"
          middle="title"
          onGoBack={() => onClickGoBack()}
          titleValue={intl.formatMessage(messages.title)}
        />
      </FenixHeader>
    </>
  );
};

Header.propTypes = {
  onClickGoBack: PropTypes.func.isRequired,
};
