import React from 'react';
import PropTypes from 'prop-types';
import Disclaimer from './components/Disclaimer';
import DisplayAmount from './components/DisplayAmount';

function SavingCard({ savings, subscription }) {
  return (
    <>
      <Disclaimer savings={savings} />
      <DisplayAmount savings={savings} suscription={subscription} />
    </>
  );
}

SavingCard.propTypes = {
  savings: PropTypes.object,
  subscription: PropTypes.object,
};

export default SavingCard;
