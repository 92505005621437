import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@pedidosya/web-fenix/system/Snackbar';
import { useLocation } from 'react-router-dom';
import useClearRouterState from '@app/hooks/useClearRouterState';

const Feedback = ({ stateValue }) => {
  const [showSnackbar, setShowSnackbar] = useState(true);
  const { state } = useLocation();

  useClearRouterState();

  if (!state || !stateValue || !state[stateValue]) return null;

  return (
    <Snackbar
      label={state[stateValue]?.label}
      type={state[stateValue]?.type}
      show={showSnackbar}
      position="bottom-center"
      onClose={() => setShowSnackbar(false)}
    />
  );
};

Feedback.propTypes = {
  stateValue: PropTypes.string,
};

export default Feedback;
