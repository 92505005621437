import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@pedidosya/web-fenix/foundations/Typography';
import { TermsAndCondition } from './TermsAndCondition';
import { TextBlock, Container } from './styled-components';
import messages from './messages';

function TyC({ terms_and_conditions, onTermAndConditionClick }) {
  return (
    <Container>
      <TextBlock>
        <Typography token="text-base-medium" color="text-color-tertiary">
          <FormattedMessage {...messages.cancelSubscriptionTitle} />
        </Typography>
        {terms_and_conditions &&
          terms_and_conditions.map((tyc) => (
            <TermsAndCondition
              key={tyc}
              type={tyc}
              onTermAndConditionClick={onTermAndConditionClick}
            />
          ))}
      </TextBlock>
    </Container>
  );
}

export default TyC;
