import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import List from '@pedidosya/web-fenix/system/List';
import Benefit from './components/Benefit';

import styled from 'styled-components';

const ListContainer = styled.div`
  margin-top: ${({ theme }) => theme.space('spacing-layout-large')};
`;

import messages from '@app/pages/status/messages';

function Benefits({ benefits, onConditionsClick }) {
  const intl = useIntl();
  const titleValue = intl.formatMessage(messages.revampBenefitsTitle);

  return (
    <ListContainer>
      <List collapseRows={1} type="default" collapsable={false}>
        <List.Title titleValue={titleValue} />
        {benefits?.map((benefit) => (
          <List.Row key={benefit?.title}>
            <Benefit benefit={benefit} onConditionsClick={() => onConditionsClick(benefit)} />
          </List.Row>
        ))}
      </List>
    </ListContainer>
  );
}

Benefits.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.object),
  onConditionsClick: PropTypes.func,
};

export default Benefits;
