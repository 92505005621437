import { defineMessages } from 'react-intl';

export default defineMessages({
  changePaymentMethod: {
    id: 'app.status.detail.changePaymentMethod',
    defaultMessage: 'Cambiar medio de pago',
  },
  addPaymentInstrumentToChange: {
    id: 'app.status.detail.addPaymentInstrumentToChange',
    defaultMessage: 'Añadí una nueva tarjeta en tu próximo pedido para cambiar de método de pago.',
  },
});
