import React from 'react';
import PropTypes from 'prop-types';
import Image from '@pedidosya/web-fenix/foundations/Image';
import { BENEFIT_TYPES } from '@commons/models/benefitTypeEnum';
import { URL_ICONS } from '@commons/models/benefitIconUrl';

import DeliveryMotorcycle from '@pedidosya/web-fenix/icons/DeliveryMotorcycle';
import Cart from '@pedidosya/web-fenix/icons/Cart';
import Discount from '@pedidosya/web-fenix/icons/Discount';
import CardIcon from '@pedidosya/web-fenix/icons/CardIcon';

// Validar con UX
import Drink from '@pedidosya/web-fenix/icons/Drink';
import Basket from '@pedidosya/web-fenix/icons/Basket';
import HelpCenter from '@pedidosya/web-fenix/icons/HelpCenter';

// Falta LUNCH y CUSTOMER_SUPPORT_PLUS

const ICON_BY_TYPE = {
  [BENEFIT_TYPES.DELIVERY_FEE]: DeliveryMotorcycle,
  [BENEFIT_TYPES.DMART_CHARGEBACKS]: Cart,
  [BENEFIT_TYPES.DMARTS]: Cart,
  [BENEFIT_TYPES.VISA_MARKDOWN]: CardIcon,
  // Este icono es de Adoption (no se usa en el Revamp)
  [BENEFIT_TYPES.LUNCH]: Basket,
  // Este icono es de Adoption (no se usa en el Revamp)
  [BENEFIT_TYPES.DRINKS]: Drink,
  // Este icono NO se usa
  [BENEFIT_TYPES.CUSTOMER_SUPPORT_PLUS]: HelpCenter,
  [BENEFIT_TYPES.GENERIC_BENEFIT]: Discount,
};

function BenefitDetailIcon({ type, ...props }) {
  const IconComponent = ICON_BY_TYPE[type];

  if (IconComponent) {
    return <IconComponent {...props} />;
  }

  return null;
}

BenefitDetailIcon.propTypes = {
  type: PropTypes.oneOf(Object.keys(ICON_BY_TYPE)),
};

export default BenefitDetailIcon;
