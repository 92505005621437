import React from 'react';
import PropTypes from 'prop-types';
import FooterFenix from '@pedidosya/web-fenix/system/Footer';
import FooterButton from '@pedidosya/web-fenix/system/FooterButton';

export const Footer = ({ onClose, onCancel }) => {
  return (
    <FooterFenix
      isSticky
      isShadowVisible={false}
      footerType={
        <FooterButton>
          <FooterButton.Button fullWidth label="Seguir con Plus" size="large" onClick={onClose} />
          <FooterButton.Button
            fullWidth
            hierarchy="secondary"
            label={'Cancelar suscripción'}
            size="large"
            onClick={onCancel}
          />
        </FooterButton>
      }
    />
  );
};

Footer.propTypes = {
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};
