import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FenixDisplayAmount from '@pedidosya/web-fenix/patterns/DisplayAmount';

function DisplayAmount({ savings, subscription }) {
  const intl = useIntl();
  return (
    // UX pidio que se corrija/haga ticket de ajuste para fixear los spacings del DisplayAmount
    <FenixDisplayAmount
      amount={intl.formatNumber(savings?.total, {
        maximumFractionDigits: Number.isInteger(savings?.total) ? 0 : 2,
      })}
      currency={subscription?.currency_symbol}
      withAction={false}
    />
  );
}

DisplayAmount.propTypes = {
  savings: PropTypes.object,
  subscription: PropTypes.object,
};

export default DisplayAmount;
