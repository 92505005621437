import React from 'react';
import FenixSkeleton from '@pedidosya/web-fenix/system/Skeleton';
import RowInformativeM from '@pedidosya/web-fenix/system/RowInformativeM';
import RowInteractiveL from '@pedidosya/web-fenix/system/RowInteractiveL';

const ROWS = 3;

const Skeleton = () => {
  return (
    <>
      <RowInformativeM
        primaryText={
          <FenixSkeleton
            borderRadiusSize="medium"
            height="shape-size-action-3"
            width="shape-size-action-65"
          />
        }
      />

      {Array.apply(0, Array(ROWS)).map((x, idx) => {
        return (
          <RowInteractiveL
            label={
              <FenixSkeleton
                borderRadiusSize="medium"
                height="shape-size-action-3"
                width="shape-size-action-20"
              />
            }
            primaryLeftText={
              <FenixSkeleton
                borderRadiusSize="medium"
                height="shape-size-action-3"
                width="shape-size-action-20"
              />
            }
            ctaType="navigable"
            onClickRow={() => {}}
          >
            <RowInteractiveL.RightElementCTA>
              <FenixSkeleton
                borderRadiusSize="medium"
                height="shape-size-action-3"
                width="shape-size-action-20"
              />
            </RowInteractiveL.RightElementCTA>
          </RowInteractiveL>
        );
      })}
    </>
  );
};

Skeleton.propTypes = {};

export default Skeleton;
