export const BOOLEAN_FLAGS = {
  WEB_LOYALTY_PROGRAM_SIDE_MENU: {
    KEY: 'web-loyalty-program-side-menu',
  },
  WEB_LOYALTY_PROGRAM_BILLS: {
    KEY: 'web-loyalty-program-bills',
  },
  WEB_LOYALTY_PROGRAM_CONGRATS_BACK: {
    KEY: 'web-loyalty-program-congrats_back',
  },
  WEB_LOYALTY_PROGRAM_REVAMP: {
    KEY: 'web-loyalty-program-revamp',
  },
  WEB_LOYALTY_PROGRAM_DEPRECATE_LANDING_LEGACY: {
    KEY: 'web-loyalty-program-deprecate-landing-legacy',
  },
};

export const EXPERIMENTAL_FLAGS = {
  WEB_LOYALTY_PROGRAM_SURVEY: {
    KEY: 'web-loyalty-program-survey',
  },
  USE_NATIVE_PAYMENT_METHOD_CHANGE: {
    KEY: 'loyalty-use-native-payment-method-change',
  },
};

export const FLAGS_KEYS = Object.values(BOOLEAN_FLAGS).map((x) => x.KEY);
