import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BoxInteractive from '@pedidosya/web-fenix/system/BoxInteractive';
import Disclaimer from '@pedidosya/web-fenix/system/Disclaimer';
import differenceInCalendarDays from '@commons/utils/date/differenceInCalendarDays';

import Settings from '@pedidosya/web-fenix/icons/Settings';
import Briefcase from '@pedidosya/web-fenix/icons/Briefcase';
import ThumbnailIcon from '@pedidosya/web-fenix/system/ThumbnailIcon';
import messages from '@app/pages/status/messages';
import { useIntl } from 'react-intl';
import { formatDateToDayMonth } from '@commons/utils/date/date';
import { isFreeTrial, isPercentage } from '@commons/utils/markdowns';
import { TRACKING_CLICK_LOCATIONS } from '@commons/constants/tracker';

import { SUBSCRIPTION_STATUS } from '@commons/models/subscriptionStatusEnum';

import landingMessage from '@app/pages/landing/components/plan/messages';
import { getUnitIdFromValue } from '@commons/models/durationUnitEnum';

const DetailCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.space('spacing-layout-xlarge')};
  margin-bottom: ${({ theme }) => theme.space('spacing-layout-large')};
`;

const DisclaimerContainer = styled.div`
  margin-top: ${({ theme }) => theme.space('spacing-layout-small')};
  margin-bottom: ${({ theme }) => theme.space('spacing-layout-xsmall')};
  margin-right: ${({ theme }) => theme.space('spacing-component-xlarge')};
  margin-left: ${({ theme }) => theme.space('spacing-component-xlarge')};
`;

function DetailCard({ subscription, trackClick, onBoxInteractiveClick, isBusinessUser }) {
  const intl = useIntl();

  const { next_charge_date, status, reactivation_enabled, markdown } = subscription;
  const { for_reactivation, discount_amount, duration_value, duration_unit } = markdown || {};
  const freeTrialMarkdown = isFreeTrial(markdown);
  const percentageMarkdowns = isPercentage(markdown);

  const isCancelled = status === SUBSCRIPTION_STATUS.CANCELLED;
  const canReactivate = isCancelled && reactivation_enabled && !isBusinessUser;

  const remainingDays = differenceInCalendarDays(new Date(next_charge_date), new Date(Date.now()));
  const cancelledDisclaimerTitle = intl.formatMessage(messages.revampCancelledCardTitle);
  const cancelledDisclaimerSubtitle = intl.formatMessage(messages.revampCancelledCardSubtitle, {
    remainingDays: remainingDays,
  });

  const onDetailCardClick = () => {
    if (trackClick && typeof trackClick === 'function') trackClick(TRACKING_CLICK_LOCATIONS.MANAGE);
    if (onBoxInteractiveClick && typeof onBoxInteractiveClick === 'function')
      onBoxInteractiveClick();
  };

  const getTitle = () => {
    if (canReactivate) {
      return intl.formatMessage(messages.revampReactivateCardTitle);
    }
    if (!canReactivate && !isBusinessUser) {
      return intl.formatMessage(messages.cardTitle);
    }
    if (!canReactivate && isBusinessUser) {
      return intl.formatMessage(messages.plusCardTitle);
    }
  };

  const getSubtitle = () => {
    if (isBusinessUser) {
      return intl.formatMessage(messages.plusCardSubtitle);
    }
    if (!canReactivate) {
      return intl.formatMessage(messages.revamapCardSubtitle);
    }
    if (!markdown && !isBusinessUser) {
      return intl.formatMessage(messages.revampReactivateCardSubtitle);
    }
    if (!for_reactivation && !isBusinessUser) {
      const discount = percentageMarkdowns
        ? intl.formatMessage(messages.planDiscountTitle, {
            discountAmountText: `${Math.floor(discount_amount)}%`,
          })
        : null;

      return percentageMarkdowns
        ? intl.formatMessage(messages.revampReactivateCardSubtitleMarkdownPerc, { tag: discount })
        : intl.formatMessage(messages.revampReactivateCardSubtitleMarkdown, {
            tag: 'gratis',
            remainingDate: formatDateToDayMonth(next_charge_date),
          });
    }

    return intl.formatMessage(messages.revampReactivateCardSubtitleMarkdownNew, {
      tag: freeTrialMarkdown
        ? intl.formatMessage(landingMessage.durationText, {
            value: duration_value,
            unit: getUnitIdFromValue(duration_unit),
          }) + ' gratis'
        : discount_amount + '% OFF',
    });
  };

  const title = getTitle();
  const subtitle = getSubtitle();

  return (
    <DetailCardContainer>
      {isCancelled && (
        <DisclaimerContainer>
          <Disclaimer
            category="warning"
            drop="down"
            primaryText={cancelledDisclaimerTitle}
            secondaryText={cancelledDisclaimerSubtitle}
          />
        </DisclaimerContainer>
      )}
      <BoxInteractive
        type="navegable"
        primaryTextType="title"
        primaryText={title}
        secondaryText={subtitle}
        onClick={!isBusinessUser && onDetailCardClick}
        rightElement={
          !isBusinessUser && {
            subtype: 'icon_button',
            placeholder: 'Tertiary button',
          }
        }
        leftElement={
          <ThumbnailIcon size="M" stroke type="Thumbnail">
            {isBusinessUser ? <Briefcase /> : <Settings />}
          </ThumbnailIcon>
        }
      />
    </DetailCardContainer>
  );
}

DetailCard.propTypes = {
  subscription: PropTypes.object,
  trackClick: PropTypes.func,
  onBoxInteractiveClick: PropTypes.func.isRequired,
  isBusinessUser: PropTypes.bool,
};

export default DetailCard;
