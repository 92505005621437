import * as React from 'react';

export const useScroll = (defaultState = false) => {
  const [scrolled, setScrolled] = React.useState(defaultState);

  React.useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [scrolled, setScrolled];
};
