import * as React from 'react';
import { useIntl } from 'react-intl';
import TopAppBar from '@pedidosya/web-fenix/system/TopAppBar';
import { useScroll } from '@app/hooks/useScroll';
import useBackNavigation from '@app/pages/status/hooks/useBackNavigation';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import HeaderIOS from '@app/components/Revamp/HeaderIOS';
import messages from '../../../messages';
import styled, { useTheme } from 'styled-components';

import { STATUS_PAGE_PEDIDOSYA_LOGO } from '@commons/constants/images';

const TopAppBarContainer = styled.div`
  background-color: ${({ bgColor, scrolled }) => !scrolled && bgColor};

  > nav {
    ${({ isWebviewIos, scrolled, theme }) =>
      isWebviewIos &&
      scrolled &&
      `
        @media ${theme.mediaQueries.mobileIos} {
        top: ${theme.space('spacing-10')};
      }

      @media ${theme.mediaQueries.mobileIosNotch} {
        top: ${theme.space('spacing-20')};
      }
    `}
  }
`;

function Header() {
  const intl = useIntl();
  const [scrolled] = useScroll(false);
  const handleBack = useBackNavigation({ isHistoryRoot: true });
  const isWebviewIos = useIsWebviewIos();

  const theme = useTheme();

  const resolvedColor = theme.color('shape-color-surface-secondary');
  const titleValue = intl.formatMessage(messages.headerTopAppBarTitle);
  const backgroundColor = theme.color('shape-color-background-primary');

  return (
    <TopAppBarContainer bgColor={resolvedColor} scrolled={scrolled} isWebviewIos={isWebviewIos}>
      <HeaderIOS {...(scrolled && { backgroundColor: backgroundColor, isSticky: true })} />
      <TopAppBar
        asset={!scrolled && STATUS_PAGE_PEDIDOSYA_LOGO}
        left="icon"
        middle="title"
        onGoBack={handleBack}
        variant="inverted"
        position={!scrolled ? 'sticky' : 'fixed'}
        assetAriaLabel="PedidosYa Logo"
        titleValue={scrolled && titleValue}
      />
    </TopAppBarContainer>
  );
}

export default Header;
