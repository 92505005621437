import { defineMessages } from 'react-intl';

export default defineMessages({
  brandError: {
    id: 'app.brandError.genericError',
    defaultMessage: 'No pudimos llevarte a donde esperabas.',
  },
  cancelModalErrorTitle: {
    id: 'app.status.cancelModalErrorTitle',
    defaultMessage: 'No pudimos cancelar tu suscripción',
  },
  cancelModalErrorMessage: {
    id: 'app.status.cancelModalErrorMessage',
    defaultMessage: 'Intenta nuevamente en unos minutos.',
  },
  reactivateCardTitle: {
    id: 'app.status.reactivateCardTitle',
    defaultMessage: 'Reactivá tu suscripción',
  },
  revampReactivateCardTitle: {
    id: 'app.status.revampReactivateCardTitle',
    defaultMessage: 'Reactivar suscripción',
  },
  cardTitle: {
    id: 'app.status.cardTitle',
    defaultMessage: 'Gestionar suscripción',
  },
  cardSubtitle: {
    id: 'app.status.cardSubtitle',
    defaultMessage: 'Tus pagos, ayuda y más',
  },
  revamapCardSubtitle: {
    id: 'app.status.revamapCardSubtitle',
    defaultMessage: 'Tus pagos y ayuda en línea',
  },
  plusCardTitle: {
    id: 'app.status.plusCardTitle',
    defaultMessage: 'Plus corporativo',
  },
  plusCardSubtitle: {
    id: 'app.status.plusCardSubtitle',
    defaultMessage: 'Plus es gratis para vos.',
  },
  reactivateCardSubtitle: {
    id: 'app.status.reactivateCardSubtitle',
    defaultMessage: 'No pierdas tus beneficios Plus',
  },
  reactivateCardSubtitleMarkdown: {
    id: 'app.status.reactivateCardSubtitleMarkdown',
    defaultMessage: 'Continuá hasta el {remainingDate} {tag}',
  },
  reactivateCardSubtitleMarkdownPerc: {
    id: 'app.status.reactivateCardSubtitleMarkdownPerc',
    defaultMessage: 'Continuá con {tag}',
  },
  reactivateCardSubtitleMarkdownNew: {
    id: 'app.status.reactivateCardSubtitleMarkdownNew',
    defaultMessage: 'Te damos {tag}',
  },
  revampReactivateCardSubtitle: {
    id: 'app.status.revampReactivateCardSubtitle',
    defaultMessage: 'No pierdas tus beneficios Plus',
  },
  revampReactivateCardSubtitleMarkdown: {
    id: 'app.status.revampReactivateCardSubtitleMarkdown',
    defaultMessage: 'Continuá hasta el {remainingDate} {tag}',
  },
  revampReactivateCardSubtitleMarkdownPerc: {
    id: 'app.status.revampReactivateCardSubtitleMarkdownPerc',
    defaultMessage: 'Continuá con {tag}',
  },
  revampReactivateCardSubtitleMarkdownNew: {
    id: 'app.status.revampReactivateCardSubtitleMarkdownNew',
    defaultMessage: 'Te damos {tag}',
  },
  title: {
    id: 'app.status.title',
    defaultMessage: 'Tu suscripción Plus',
  },
  cancelledStatusTitle: {
    id: 'app.status.cancelledStatusTitle',
    defaultMessage: 'Cancelaste tu suscripción',
  },
  activeSubtitle: {
    id: 'app.status.activeSubtitle',
    defaultMessage:
      'La {isFirst, select, true {primera} false {próxima} other {próxima}} fecha de pago es { remainingDays, plural, =0 {hoy} one {mañana} other {en # días}}.',
  },
  helpNeeded: {
    id: 'app.status.helpNeeded',
    defaultMessage: '¿Necesitás ayuda?',
  },
  cancelButton: {
    id: 'app.status.cancelButton',
    defaultMessage: 'Cancelar suscripción',
  },
  planTitle: {
    id: 'app.status.planTitle',
    defaultMessage: 'Plan mensual',
  },
  planSubtitle: {
    id: 'app.status.planSubtitle',
    defaultMessage: 'Sos plus desde {month} {year}',
  },
  revampCancelledCardTitle: {
    id: 'app.status.revampCancelledCardTitle',
    defaultMessage: 'Cancelaste tu suscripción',
  },
  revampCancelledCardSubtitle: {
    id: 'app.status.revampCancelledCardSubtitle',
    defaultMessage: 'Finaliza { remainingDays, plural, =0 {hoy} one {mañana} other {en # días}}',
  },
  cancelledCardTitle: {
    id: 'app.status.cancelledCardTitle',
    defaultMessage:
      'Cancelaste tu suscripción - Finaliza { remainingDays, plural, =0 {hoy} one {mañana} other {en # días}}',
  },
  cancelledTitle: {
    id: 'app.status.cancelledTitle',
    defaultMessage: 'Finaliza el {nextChargeDate} y no se renovará.',
  },
  cancelledSubtitle: {
    id: 'app.status.cancelledSubtitle',
    defaultMessage: 'Para volver a suscribirte tienes que esperar a que termine el ciclo actual.',
  },
  planDiscountTitle: {
    id: 'app.status.planDiscountTitle',
    defaultMessage: '{discountAmountText} OFF',
  },
  promotionSubtitle: {
    id: 'app.status.promotionSubtitle',
    defaultMessage: 'Promoción hasta el {date}',
  },
  paymentsHistory: {
    id: 'app.status.paymentsHistory',
    defaultMessage: 'Consultar historial de pagos',
  },
  paymentsTitle: {
    id: 'app.status.paymentsTitle',
    defaultMessage: 'Historial de pagos',
  },
  paymentsSubtitle: {
    id: 'app.status.paymentsSubtitle',
    defaultMessage: 'Podés descargar la factura a tu dispositivo.',
  },
  paymentsSubtitleSend: {
    id: 'app.status.paymentsSubtitleSend',
    defaultMessage: 'Podés enviar la factura a tu cuenta de e-mail.',
  },
  paymentsContact: {
    id: 'app.status.paymentsContact',
    defaultMessage: 'Contactanos en caso de necesitar facturas previas',
  },
  paymentsSendError: {
    id: 'app.status.paymentsSendError',
    defaultMessage: 'No pudimos enviarte la factura',
  },
  paymentDocumentName: {
    id: 'app.status.paymentDocumentName',
    defaultMessage: 'Factura',
  },
  savingOrders: {
    id: 'app.status.savingOrders',
    defaultMessage: '{orders, plural, =0 {# pedido} =1 {# pedido} other {# pedidos} }',
  },
  reactivateSubscription: {
    id: 'app.status.reactivateSubscription',
    defaultMessage: '¡Reactivá tu suscripción!',
  },
  reactivateSubscriptionSubtitle: {
    id: 'app.status.reactivateSubscriptionSubtitle',
    defaultMessage:
      'No pierdas tus beneficios Plus. Te cobraremos mensualmente con la misma tarjeta que cargaste.',
  },
  reactivateSubscriptionSubtitleMarkdown: {
    id: 'app.status.reactivateSubscriptionSubtitleMarkdown',
    defaultMessage:
      'Continuá con tu beneficio hasta el {remainingDate}. Luego, te cobraremos mensualmente con la misma tarjeta que cargaste.',
  },
  reactivateSubscriptionSubtitleMarkdownNewFree: {
    id: 'app.status.reactivateSubscriptionSubtitleMarkdownNewFree',
    defaultMessage:
      'Disfrutá Plus {duration} gratis. Luego, te cobraremos mensualmente con la misma tarjeta que cargaste.',
  },
  reactivateSubscriptionSubtitleMarkdownNewPerc: {
    id: 'app.status.reactivateSubscriptionSubtitleMarkdownNewPerc',
    defaultMessage:
      'Disfrutá Plus {duration} al {percentage}% OFF. Te cobraremos mensualmente con la misma tarjeta que cargaste.',
  },
  reactivateSubscriptionPositiveMessage: {
    id: 'app.status.reactivateSubscriptionPositiveMessage',
    defaultMessage: '¡Continuás siendo Plus! Seguí disfrutando tus beneficios',
  },
  reactivateSubscriptionErrorMessage: {
    id: 'app.status.reactivateSubscriptionErrorMessage',
    defaultMessage: 'No pudimos reactivar tu suscripción, volvé a intentar',
  },
  headerTitle: {
    id: 'app.status.headerTitle',
    defaultMessage: 'Tu suscripción',
  },
  headerSubtitle: {
    id: 'app.status.headerSubtitle',
    defaultMessage: ' Sos Plus desde {month}/{year}',
  },
  headerTopAppBarTitle: {
    id: 'app.status.headerTopAppBarTitle',
    defaultMessage: 'PedidosYa Plus',
  },
  initialSectionSubtitle: {
    id: 'app.status.initialSectionSubtitle',
    defaultMessage: 'Empezá a ahorrar con estos beneficios!',
  },
  initialSectionSubtitleSaving: {
    id: 'app.status.initialSectionSubtitleSaving',
    defaultMessage: '¡Seguí aprovechando tus beneficios!',
  },
  revampFeedbackPageErrorTitle: {
    id: 'app.status.cancelSuscriptionQueryErrorTitle',
    defaultMessage: 'Lo sentimos, tuvimos un problema',
  },
  revampFeedbackPageErrorDescription: {
    id: 'app.status.cancelSuscriptionQueryErrorDescription',
    defaultMessage: 'Ya estamos trabajando para resolverlo, volvé a intentarlo en unos minutos.',
  },
  revampFeedbackPageErrorButtonLabel: {
    id: 'app.status.cancelSuscriptionQueryErrorButtonLabel',
    defaultMessage: 'Volver a intentar',
  },
  revampBenefitsTitle: {
    id: 'app.status.revampBenefitsTitle',
    defaultMessage: 'Tus beneficios',
  },
});
