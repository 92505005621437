import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHeaderConfiguration } from '../../hooks/useHeaderConfiguration';
import { ROUTES } from '@commons/services/routes.service';
import { useFlagVariation } from '@app/contexts/FwfFlags/hooks';
import { BOOLEAN_FLAGS } from '@commons/fwf/constants';
import { Cancel } from './components/Cancel';
import Spinner from '../../components/Spinner';
import { BrandError, DialogBrandError } from '../../components/Error';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { cancelSubscription } from '@app/services/loyalty/cancelSubscription';
import { fetchSubscription } from '../../services/loyalty/fetchSubscription';
import { useCancellationPageTracker } from './tracking/CancellationPageTracker';
import { usePublicEnv } from '@app/contexts/PublicEnv';

import messages from './messages';
import FeedbackPage from '@app/components/Revamp/FeedbackPage';
import { getHomeLink } from '@commons/utils/deeplinks';
import { useDeviceInfo } from '@app/contexts/deviceInfo';
import logger from '@app/logger';

export const CancellationPage = () => {
  const deviceInfo = useDeviceInfo();
  const history = useHistory();
  const queryClient = useQueryClient();
  const subscriptionQuery = useQuery(queryKeyBuilders.subscription(), () => fetchSubscription());
  const { data, isLoading, isError } = subscriptionQuery;
  const [cancelLoaded, setCancelLoaded] = useState(false);
  const { isRevamp } = usePublicEnv();
  const revampFlag = useFlagVariation(BOOLEAN_FLAGS.WEB_LOYALTY_PROGRAM_REVAMP.KEY);

  const { trackClick, trackUnsubscribe } = useCancellationPageTracker({
    isErrorPage: isError,
    isPageReady: !isLoading,
  });

  const [showCancellationError, setShowCancellationError] = useState(false);
  const intl = useIntl();

  useHeaderConfiguration();

  const goBack = () => {
    history.length > 1 ? history.goBack() : history.replace(ROUTES.STATUS);
  };

  const goToStatus = () => {
    history.replace(ROUTES.STATUS, {
      cancelFeedback: {
        label: intl.formatMessage(messages.cancelSubscriptionPositiveMessage),
        type: 'positive',
      },
    });
  };

  const updateStatus = () => {
    queryClient.removeQueries(queryKeyBuilders.subscription());
    queryClient.removeQueries(queryKeyBuilders.status());
  };

  const onCancelSubscriptionBrandErrorClose = () => {
    setShowCancellationError(false);
  };

  const cancelSubscriptionMutation = useMutation(cancelSubscription);
  const onCancelSubscription = async () => {
    trackUnsubscribe();
    const planId = data?.plan_id;

    try {
      setCancelLoaded(true);
      await cancelSubscriptionMutation.mutateAsync({ planId });
      updateStatus();
      goToStatus();
    } catch (error) {
      logger.error(error);
      setShowCancellationError(true);
    } finally {
      setCancelLoaded(false);
    }
  };

  if (isLoading || cancelLoaded) {
    return <Spinner />;
  }

  if (isError) {
    console.log('entra o no entra al isError ???');
    if (revampFlag && isRevamp) {
      return (
        <>
          <FeedbackPage
            onFirstCTAClick={subscriptionQuery.refetch}
            firstCTALabel={intl.formatMessage(messages.cancelSuscriptionQueryErrorButtonLabel)}
            backAction={goBack}
            showClose={false}
            title={intl.formatMessage(messages.cancelSuscriptionQueryErrorTitle)}
            description={intl.formatMessage(messages.cancelSuscriptionQueryErrorDescription)}
            data-testid="feedback-page"
          />
        </>
      );
    }

    return (
      <BrandError
        message={<FormattedMessage {...messages.cancelModalErrorTitle} />}
        primaryActionLabel="Intentar nuevamente"
        primaryActionClick={subscriptionQuery.refetch}
        secondaryActionLabel="Ir al inicio"
        secondaryActionClick={() => {
          window.location = getHomeLink(deviceInfo);
        }}
      />
    );
  }

  return (
    <>
      <Cancel
        onGoBack={goBack}
        onCancelSubscription={onCancelSubscription}
        isRevamp={revampFlag && isRevamp}
      />
      <DialogBrandError
        open={showCancellationError}
        onClose={onCancelSubscriptionBrandErrorClose}
        message={intl.formatMessage(messages.cancelModalErrorTitle)}
        title={intl.formatMessage(messages.cancelModalErrorMessage)}
      />
    </>
  );
};
