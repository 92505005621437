import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Header } from './Header';
import { PlanDetail } from './PlanDetail';
import { useDeviceInfo } from '@app/contexts/deviceInfo';
import getPaymentMethodLogo from '@app/utils/images/paymentMethodLogo';
import { TRACKING_CLICK_LOCATIONS } from '@commons/constants/tracker';
import differenceInCalendarDays from '@commons/utils/date/differenceInCalendarDays';
import { hasFreeTrial } from '@commons/utils/markdowns';
import { SUPPORT_LINKS } from './constants';
import PaymentMethod from '../PaymentMethod';
import { Feedback } from '@app/components/Feedback';
import PaymentIssueNotification from './PaymentIssueNotification';
import AlternatePaymentCard from './AlternatePaymentCard';
import Footer from '@pedidosya/web-fenix/system/Footer';
import FooterButton from '@pedidosya/web-fenix/system/FooterButton';
import RowInformativeL from '@pedidosya/web-fenix/system/RowInformativeL';
import ImageContainerPayment from '@pedidosya/web-fenix/system/ImageContainerPayment';
import ChevronRight from '@pedidosya/web-fenix/icons/ChevronRight';
import { Container } from './styled-components';
import messages from '../../../messages';

const paymentMethodTypes = {
  CREDIT: 'Crédito',
  DEBIT: 'Débito',
};

const changeOtherCardsTypes = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

const getSupportLink = (deviceInfo) => {
  if (!deviceInfo.isWebview()) return SUPPORT_LINKS.WEB;
  if (deviceInfo.isIos()) return SUPPORT_LINKS.IOS;
  if (deviceInfo.isAndroid()) return SUPPORT_LINKS.ANDROID;

  return '';
};

export const DetailPage = ({
  subscription,
  onClose,
  trackClick = () => {},
  purchase,
  paymentInformation,
  changeInstrument,
  notification,
  paymentConfiguration,
  onChangePaymentConfig,
  onCancelPageOpen,
}) => {
  const { plan_id, next_charge_date } = subscription;
  const { markdowns } = purchase;
  const intl = useIntl();
  const deviceInfo = useDeviceInfo();

  const icon = getPaymentMethodLogo(paymentInformation.brand);

  const freeTrialMarkdown = hasFreeTrial(markdowns);
  const [first] = markdowns || [];
  const { campaign_period_valid_to } = first || {};
  const { charge_other_payment_instruments } = paymentConfiguration || {};
  const showOtherCards = !!charge_other_payment_instruments;

  const remainingDays = differenceInCalendarDays(
    new Date(freeTrialMarkdown ? campaign_period_valid_to : next_charge_date),
    new Date(Date.now()),
  );

  const clickWithTrack = (clickLocation, clickHandler) => (args) => {
    if (trackClick && typeof trackClick === 'function') trackClick(clickLocation);
    if (clickHandler && typeof clickHandler === 'function') clickHandler(args);
  };

  const onCancelSubscriptionClick = () => {
    clickWithTrack(TRACKING_CLICK_LOCATIONS.CANCEL_CLICK);
    onCancelPageOpen();
  };

  const onSupportClick = () => {
    clickWithTrack(TRACKING_CLICK_LOCATIONS.HELP);
    window.location.href = getSupportLink(deviceInfo);
  };

  return (
    <>
      <Header onClickGoBack={onClose} />
      <PlanDetail
        subscription={subscription}
        purchase={purchase}
        onShowPayments={clickWithTrack(TRACKING_CLICK_LOCATIONS.PAYMENTS)}
      />
      <RowInformativeL
        primaryLeftText={`${paymentMethodTypes[paymentInformation.type]} **** ${
          paymentInformation.last_digits
        }`}
        secondaryLeftText={
          remainingDays >= 0 && (
            <FormattedMessage
              {...messages.activeSubtitle}
              values={{ isFirst: !!freeTrialMarkdown, remainingDays }}
            />
          )
        }
      >
        <ImageContainerPayment src={icon} variant="default" size="xsmall" imageAlt="Tarjeta" />
      </RowInformativeL>

      {notification && (
        <PaymentIssueNotification
          title={notification?.title}
          description={notification?.description}
          severity={notification?.severity}
        />
      )}

      <PaymentMethod
        instrumentId={paymentInformation.instrument_id}
        changeInstrument={changeInstrument}
        icon={<ChevronRight />}
        onChangePaymentInstrumentClick={clickWithTrack(
          TRACKING_CLICK_LOCATIONS.CHANGE_PAYMENT_INSTRUMENT,
        )}
      />

      {showOtherCards && (
        <Container>
          <AlternatePaymentCard
            planId={plan_id}
            checked={charge_other_payment_instruments === changeOtherCardsTypes.ENABLED}
            onChangeMultiplePaymentCardClick={clickWithTrack(
              TRACKING_CLICK_LOCATIONS.CHANGE_MULTIPLE_PAYMENT_CARD,
            )}
            onChangePaymentConfig={onChangePaymentConfig}
          />
        </Container>
      )}

      <Feedback stateValue="userFeedback" />
      <Footer
        isSticky
        isShadowVisible={false}
        footerType={
          <FooterButton>
            <FooterButton.Button
              fullWidth
              label={intl.formatMessage(messages.helpNeeded)}
              size="large"
              onClick={onSupportClick}
            />
            <FooterButton.Button
              fullWidth
              hierarchy="secondary"
              label={intl.formatMessage(messages.cancelButton)}
              size="large"
              onClick={onCancelSubscriptionClick}
            />
          </FooterButton>
        }
      />
    </>
  );
};

DetailPage.propTypes = {
  subscription: PropTypes.shape({
    plan_id: PropTypes.number,
    next_charge_date: PropTypes.string,
    status: PropTypes.string,
    start_date: PropTypes.string,
    currency_symbol: PropTypes.string,
  }),
  purchase: PropTypes.shape({
    markdowns: PropTypes.array,
    amount: PropTypes.number,
  }),
  paymentInformation: PropTypes.shape({
    brand: PropTypes.string,
    last_digits: PropTypes.string,
    type: PropTypes.string,
    instrument_id: PropTypes.string,
  }),
  changeInstrument: PropTypes.object,
  onClose: PropTypes.func,
  onUnsubscribe: PropTypes.func,
  notification: PropTypes.object,
};
