import React, { useContext, useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';
import Spinner from '../../components/Spinner';
import Error from '@pedidosya/web-fenix/pages/Error';
import FeedbackPage from '@app/components/Revamp/FeedbackPage';

const PublicEnvContext = createContext();

export const getPublicEnv = async () => {
  const { data } = await axios.get(`/loyalty/api/env`);

  return data;
};

export const usePublicEnv = () => {
  return useContext(PublicEnvContext);
};

export const PublicEnvProvider = ({ children, context }) => {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (publicEnv && publicEnv.redirect) {
      window.location.assign(publicEnv.redirect.location);
    } else if (!publicEnv) {
      getPublicEnv().then(setPublicEnv).catch(setError);
    }
  }, [publicEnv]);

  if (error)
    return (
      <FenixProvider config={{ revamp: publicEnv && publicEnv?.isRevamp }}>
        {publicEnv?.isRevamp ? (
          <FeedbackPage
            error={error}
            showClose={false}
            showBack={false}
            title="Lo sentimos, tuvimos un problema"
            description="Ya estamos trabajando para resolverlo, volvé a intentarlo en unos minutos."
          />
        ) : (
          <Error />
        )}
      </FenixProvider>
    );

  if (!publicEnv) return <Spinner />;

  // Don't render anything is the user is meant to be redirected
  if (publicEnv.redirect) return null;

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
