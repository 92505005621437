import * as React from 'react';
import styled from 'styled-components';
import { useDynamicStyles } from '@app/hooks/useDynamicStyles';
import { publishEventsOnDevice } from '@app/hooks/useHeaderConfiguration';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import { IMAGE_URL_DOMAIN } from '@commons/constants/imagesDomain';
import FenixFeedbackPage from '@pedidosya/web-fenix/system/FeedbackPage';
import FooterButton from '@pedidosya/web-fenix/system/FooterButton';
import HeaderIOS from '../HeaderIOS';

// This solution will be replaced soon by FenixProvider global solution.
const FeedbackContainer = styled.div`
  > div {
    min-height: ${({ dynamicHeight }) => dynamicHeight};
    padding-bottom: ${({ paddingBottom }) => paddingBottom};
  }
`;

function FeedbackPage({
  onFirstCTAClick,
  firstCTALabel,
  backAction,
  showClose,
  title,
  description,
}) {
  const isWebviewIos = useIsWebviewIos();

  const { height, padding } = useDynamicStyles(isWebviewIos);

  React.useEffect(() => {
    publishEventsOnDevice({
      theme: 'light',
    });
  }, []);

  return (
    <>
      <HeaderIOS dataTestId="feedback-page" />
      <FeedbackContainer dynamicHeight={height} paddingBottom={padding}>
        <FenixFeedbackPage
          description={description}
          imageAlt="Error image"
          // On UX request, change the default error Image for this generalError image
          imageSrc={`${IMAGE_URL_DOMAIN}/image/pedidosya/fenix/illustrations/generalErrorDisabled1MediumComp.svg`}
          title={title}
          variant="error"
        >
          <FenixFeedbackPage.TopBar left="icon" middle="title" onGoBack={backAction} right="icon">
            {showClose && (
              <Right>
                <ButtonIcon icon={<Close size="medium" />} onClick={backAction} />
              </Right>
            )}
          </FenixFeedbackPage.TopBar>
          {firstCTALabel && (
            <FenixFeedbackPage.FooterButton>
              {firstCTALabel && (
                <FooterButton.Button
                  fullWidth
                  hierarchy="primary"
                  label={firstCTALabel}
                  onClick={onFirstCTAClick}
                  size="large"
                />
              )}
            </FenixFeedbackPage.FooterButton>
          )}
        </FenixFeedbackPage>
      </FeedbackContainer>
    </>
  );
}

export default FeedbackPage;
