import styled from 'styled-components';

export const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const NavbarIcon = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.space('spacing-5')};
  padding-right: ${({ theme }) => theme.space('spacing-5')};
`;

export const NavbarRightSpacer = styled.div`
  width: calc(
    ${({ theme }) => theme.space('spacing-5')} + ${({ theme }) => theme.size('icon-size-medium')}
  );
`;

export const HeaderIOS = styled.div`
  width: 100%;

  ${({ isWebviewIos, theme }) =>
    /* The following values are hardcoded as they don't depend on fenix tokens */
    isWebviewIos &&
    `
    @media ${theme.mediaQueries.mobileIos} {
      padding-top: 20px;
    }

    @media ${theme.mediaQueries.mobileIosNotch} {
      padding-top: 40px;
    }
  `}
`;

export const DetailPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};

  ${({ isWebviewIos, theme }) =>
    isWebviewIos
      ? `
      @media ${theme.mediaQueries.mobileIosNotch} {
        padding-bottom: ${theme.space('spacing-15')};
      }
    `
      : ''}
`;

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
  padding: 0 ${({ theme }) => theme.space('spacing-8')};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space('spacing-8')};

  > * {
    order: 0;
  }
`;

export const DetailModalWrapper = styled.section`
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
`;

export const PlanDetailContent = styled.div`
  padding-top: ${({ theme }) => theme.space('spacing-8')};
`;

export const PlanPriceContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space('spacing-2')};
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const OtherCardGrid = styled.div`
  display: grid;
  grid-template-columns: auto 2fr auto;
  column-gap: ${({ theme }) => theme.space('spacing-8')};
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-xlarge')} 0 ${theme.space('spacing-component-large')}`};
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme, showOverlay }) =>
    showOverlay ? theme.color('shape-color-background-secondary') : 'transparent'};
`;

export const RightContent = styled.div`
  display: flex;
  justify-content: right;
`;

export const CardContent = styled.div`
  padding: ${({ theme }) => `0  ${theme.space('spacing-component-2xlarge')}`};
`;

export const TycContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-large')} 0 ${theme.space('spacing-component-xlarge')}`};
`;
