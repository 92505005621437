import * as React from 'react';
import { useWindowSize } from './useWindowSize';

export function useDynamicStyles(isWebviewIos) {
  const { width, height } = useWindowSize();
  const [dynamicStyles, setDynamicStyles] = React.useState('100vh');

  React.useEffect(() => {
    if (!isWebviewIos) {
      return setDynamicStyles({ height: '100vh', padding: '0px' });
    }

    const isMobileM = width >= 375;
    const isMobileIosNotch = height >= 800 && isMobileM;
    const isMobileIos = height <= 740 && isMobileM;

    if (isMobileIosNotch) {
      return setDynamicStyles({ height: 'calc(100vh - 40px)', padding: '16px' });
    } else if (isMobileIos) {
      return setDynamicStyles({ height: 'calc(100vh - 20px)', padding: '16px' });
    } else {
      return setDynamicStyles({ height: '100vh', padding: '0px' });
    }
  }, [isWebviewIos, width, height]);

  return dynamicStyles;
}
