import React from 'react';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import styled from 'styled-components';

const Header = styled.div`
  width: 100%;

  ${({ isSticky, backgroundColor }) => `
  transition: background-color 0.3s ease-in-out;
  ${
    isSticky
      ? `
    background-color: ${backgroundColor};
    position: fixed;
    z-index: 10;
  `
      : ''
  }
`}

  ${({ isWebviewIos, theme }) =>
    /* The following values are hardcoded as they don't depend on fenix tokens */
    isWebviewIos &&
    `
    @media ${theme.mediaQueries.mobileIos} {
      padding-top: 20px;
    }

    @media ${theme.mediaQueries.mobileIosNotch} {
      padding-top: 40px;
    }
  `}
`;

const HeaderIOS = ({ dataTestId, backgroundColor, isSticky = false }) => {
  const isWebviewIos = useIsWebviewIos();
  return (
    <Header
      isWebviewIos={isWebviewIos}
      data-testid={dataTestId}
      backgroundColor={backgroundColor}
      isSticky={isSticky}
    />
  );
};

export default HeaderIOS;
