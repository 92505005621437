import React from 'react';
import FenixSkeleton from '@pedidosya/web-fenix/system/Skeleton';
import { Paragraph } from './styled-components';

const Skeleton = () => {
  return (
    <>
      <Paragraph>
        <FenixSkeleton borderRadiusSize="large" height="shape-size-action-02" />
      </Paragraph>
      <Paragraph>
        <FenixSkeleton borderRadiusSize="large" height="shape-size-action-02" />
        <FenixSkeleton borderRadiusSize="large" height="shape-size-action-02" />
        <FenixSkeleton borderRadiusSize="large" height="shape-size-action-02" />
        <FenixSkeleton borderRadiusSize="large" height="shape-size-action-02" />
        <FenixSkeleton borderRadiusSize="large" height="shape-size-action-02" />
      </Paragraph>
      <Paragraph>
        <FenixSkeleton borderRadiusSize="large" height="shape-size-action-02" />
        <FenixSkeleton borderRadiusSize="large" height="shape-size-action-02" />
        <FenixSkeleton borderRadiusSize="large" height="shape-size-action-02" />
        <FenixSkeleton borderRadiusSize="large" height="shape-size-action-02" />
        <FenixSkeleton borderRadiusSize="large" height="shape-size-action-02" />
      </Paragraph>
    </>
  );
};

Skeleton.propTypes = {};

export default Skeleton;
