import React from 'react';
import PropTypes from 'prop-types';
import { Webview, Web } from '@app/contexts/deviceInfo/components';
import { Wrapper } from './styled-components';
import AndroidBackNavigation from './AndroidBackNavigation';
import IosBackNavigation from './IosBackNavigation';
import WebBackNavigation from './WebBackNavigation';
import { useTheme } from 'styled-components';

export const NavigationHeader = ({
  onNavigationClick = Function.prototype,
  color,
  isHistoryRoot = false,
}) => {
  const theme = useTheme();
  const resolvedColor = color || theme.color('shape-color-background-primary');

  return (
    <Wrapper>
      <Webview.Android>
        <AndroidBackNavigation
          color={resolvedColor}
          onNavigationClick={onNavigationClick}
          isHistoryRoot={isHistoryRoot}
        />
      </Webview.Android>
      <Webview.Ios>
        <IosBackNavigation
          color={resolvedColor}
          onNavigationClick={onNavigationClick}
          isHistoryRoot={isHistoryRoot}
        />
      </Webview.Ios>
      <Web>
        <WebBackNavigation
          color={resolvedColor}
          onNavigationClick={onNavigationClick}
          isHistoryRoot={isHistoryRoot}
        />
      </Web>
    </Wrapper>
  );
};

NavigationHeader.propTypes = {
  onNavigationClick: PropTypes.func,
  color: PropTypes.string,
  isHistoryRoot: PropTypes.bool,
  fenix: PropTypes.bool,
};
