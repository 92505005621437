import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { sendPaymentConfigurationStrategy } from '@app/services/loyalty/sendPaymentConfigurationStrategy';
import { sendEvent } from '@app/services/loyalty/sendEvent';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Card from '@pedidosya/web-fenix/molecules/CardContainer';
import CardIcon from '@pedidosya/web-fenix/icons/CardIcon';
import Switch from '@pedidosya/web-fenix/atoms/Switch';
import BoxMessage from '@pedidosya/web-fenix/molecules/BoxMessage';
import CUSTOM_EVENTS from '@commons/constants/customEvents';
import { OtherCardGrid, SwitchContainer, CardContent, TycContainer } from './styled-components';

const AlternatePaymentCard = ({
  planId,
  checked,
  onChangeMultiplePaymentCardClick,
  onChangePaymentConfig,
}) => {
  const [selected, setSelected] = useState(checked);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const { mutate, isLoading } = useMutation(sendPaymentConfigurationStrategy);

  const handleClick = () => {
    if (isLoading) return;

    changePaymentConfig();
  };

  const changePaymentConfig = () => {
    onChangeMultiplePaymentCardClick();
    sendEvent(CUSTOM_EVENTS.SUBSCRIPTION_MANAGEMENT_CLICKED_CHANGE_MULTIPLE_PAYMENT_CARD);

    const value = !selected;
    setSelected(value);
    mutate(
      { planId, strategy: value ? 'ALL' : 'ORIGINAL' },
      {
        onSuccess: () => {
          onChangePaymentConfig();
        },
        onError: (err) => {
          onError(selected);
          logger.error(err);
        },
      },
    );
  };

  const onError = (selected) => {
    setOpen(true);
    setMessage(
      `No pudimos ${
        selected ? 'deshabilitar' : 'habilitar'
      } el uso de tus tarjetas, vuelve a intentar.`,
    );
    setSelected(selected);
  };

  return (
    <>
      <Card withShadow={false}>
        <CardContent>
          <OtherCardGrid>
            <CardIcon />
            <Typography token="font-body-midcontrast-sentence-small" color="text-color-secondary">
              Usar cualquiera de mis tarjetas guardadas cuando falle el cobro de mi suscripción.
            </Typography>
            <SwitchContainer>
              <Switch state="enabled" selected={selected} onClick={handleClick} />
            </SwitchContainer>
          </OtherCardGrid>
        </CardContent>
      </Card>
      {open && (
        <BoxMessage variant="loud" type="error" message={message} onClose={() => setOpen(false)} />
      )}
    </>
  );
};

export default AlternatePaymentCard;
