import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import usePaymentInstrumentsNavigation from '../PaymentMethod/hooks/usePaymentInstrumentsNavigation';
import RowInteractiveM from '@pedidosya/web-fenix/system/RowInteractiveM';
import ButtonIcon from '@pedidosya/web-fenix/system/ButtonIcon';
import messages from '../PaymentMethod/messages';

const PaymentMethod = ({
  icon,
  instrumentId,
  changeInstrument,
  onChangePaymentInstrumentClick,
}) => {
  const intl = useIntl();
  const showChangeInstrument =
    changeInstrument.enabled || changeInstrument.reason === 'USER_ONLY_HAS_ONE_PAYMENT_INSTRUMENT';
  const openPaymentInstruments = usePaymentInstrumentsNavigation();

  const handleChangePaymentInstrumentClick = () => {
    if (changeInstrument.enabled) {
      onChangePaymentInstrumentClick();
      openPaymentInstruments(instrumentId);
    }
  };

  return (
    <>
      {showChangeInstrument && (
        <>
          <RowInteractiveM
            primaryLeftText={intl.formatMessage(messages.changePaymentMethod)}
            primaryLeftTextType="highlight"
            ctaType="navigable"
            onClickRow={handleChangePaymentInstrumentClick}
            disabled={!changeInstrument.enabled}
            {...(changeInstrument.reason === 'USER_ONLY_HAS_ONE_PAYMENT_INSTRUMENT' && {
              secondaryLeftText: intl.formatMessage(messages.addPaymentInstrumentToChange),
            })}
          >
            <RowInteractiveM.RightElementCTA>
              {' '}
              <ButtonIcon icon={icon} state="enabled" size="small" buttonStyle="default" />
            </RowInteractiveM.RightElementCTA>
          </RowInteractiveM>
        </>
      )}
    </>
  );
};

PaymentMethod.propTypes = {
  icon: PropTypes.node,
  lastDigits: PropTypes.node,
  subtitle: PropTypes.node,
  type: PropTypes.node,
  instrumentId: PropTypes.string,
  changeInstrument: PropTypes.shape({
    enabled: PropTypes.bool,
    reason: PropTypes.string,
  }),
};

export default PaymentMethod;
