import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { TRACKING_CLICK_LOCATIONS } from '@commons/constants/tracker';
import { SUBSCRIPTION_STATUS } from '@commons/models/subscriptionStatusEnum';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { Storage } from '@app/utils/storage';
import { DismissableRichtTextPopup as Popup } from '@app/components/DismissableRichTextPopup';
import {
  InitialSectionTitle,
  InitialSectionSubtitle,
  Content,
  CardContainerWrapper,
  NewsMessageWrapper,
} from './styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Divider from '@pedidosya/web-fenix/atoms/Divider';

import { Header } from '../Header';
import { HeaderTitle } from '../HeaderTitle';
import { DetailCard } from '../DetailCard';
import { Benefits } from '../Benefits';
import { Tyc } from '../TyC';
import { SavingCard } from '../SavingCard';
import { ReactivateSubscription } from '../ReactivateSubscription';
import { Feedback } from '@app/components/Feedback';
import savingVector from '@app/assets/saving_vector.svg';
import messages from '../../messages';

import RevampHeader from '../Revamp/Header';
import HeaderShowcase from '../Revamp/HeaderShowcase';
import RevampDetailCard from '../Revamp/DetailCard';
import RevampSavingCard from '../Revamp/SavingCard';
import RevampBenefits from '../Revamp/Benefits';
import RevampTyC from '../Revamp/TyC';
import RevampReactivateSubscription from '../Revamp/ReactivateSubscription';
import RevampFeedback from '@app/components/Revamp/Feedback';
import { useFlagVariation } from '@app/contexts/FwfFlags/hooks';
import { BOOLEAN_FLAGS } from '@commons/fwf/constants';

export const SubscriptionStatus = ({
  status,
  redirectToStatusPage,
  trackClick,
  onSubscriptionUpdate,
}) => {
  const intl = useIntl();
  const { action, isRevamp } = usePublicEnv();
  const [isOpenReactivateSubs, setIsOpenReactivateSubs] = useState(false);
  const { subscription, savings, newsMessage, benefits, terms_and_conditions, pop_up } = status;
  const revampFlag = useFlagVariation(BOOLEAN_FLAGS.WEB_LOYALTY_PROGRAM_REVAMP.KEY);

  const isBusinessUser = subscription.type === 'business';

  const onDetailCardClick = () => {
    if (
      status.subscription.status === SUBSCRIPTION_STATUS.CANCELLED &&
      status.subscription.reactivation_enabled
    ) {
      setIsOpenReactivateSubs(true);
    } else redirectToStatusPage();
  };

  const onCloseReactivateSubscription = () => {
    setIsOpenReactivateSubs(false);
  };

  const getInitialSectionSubtitle = () => {
    if (savings) {
      return intl.formatMessage(messages.initialSectionSubtitleSaving);
    } else return intl.formatMessage(messages.initialSectionSubtitle);
  };

  useEffect(() => {
    const showReactivate =
      status.subscription.status === SUBSCRIPTION_STATUS.CANCELLED &&
      status.subscription.reactivation_enabled &&
      action === 'OPEN_REACTIVATION';

    const storage = new Storage(window.sessionStorage);
    const actionStorageKey = `reactivate-subs-showed`;
    const openReactivate = showReactivate && !storage.getItem(actionStorageKey);

    if (openReactivate) {
      setIsOpenReactivateSubs(true);
      storage.setItem(actionStorageKey, true);
    }
  }, []);

  if (isRevamp && revampFlag) {
    return (
      <>
        <section data-testid="subscription-status-revamp">
          <>
            <RevampHeader />
            <HeaderShowcase subscription={subscription} />
            <RevampDetailCard
              subscription={subscription}
              trackClick={trackClick}
              onBoxInteractiveClick={!isBusinessUser && onDetailCardClick}
              isBusinessUser={isBusinessUser}
            />

            {savings && <RevampSavingCard subscription={subscription} savings={savings} />}

            <RevampBenefits
              benefits={benefits}
              onConditionsClick={({ type }) =>
                trackClick(
                  `${type.toLowerCase()}_${TRACKING_CLICK_LOCATIONS.TERMS_AND_CONDITIONS}`,
                  `benefit.${TRACKING_CLICK_LOCATIONS.TERMS_AND_CONDITIONS}`,
                )
              }
            />

            {!isBusinessUser && (
              <>
                <RevampTyC
                  terms_and_conditions={terms_and_conditions}
                  onTermAndConditionClick={() =>
                    trackClick(TRACKING_CLICK_LOCATIONS.TERMS_AND_CONDITIONS)
                  }
                />
              </>
            )}
          </>
        </section>

        {!isBusinessUser && (
          <RevampReactivateSubscription
            open={isOpenReactivateSubs}
            onClose={onCloseReactivateSubscription}
            subscription={status.subscription}
            onSubscriptionUpdate={onSubscriptionUpdate}
            trackClick={trackClick}
          />
        )}

        <RevampFeedback stateValue="cancelFeedback" />
      </>
    );
  }

  return (
    <>
      <section>
        <Header isCancelled={status.subscription.status === SUBSCRIPTION_STATUS.CANCELLED}>
          <HeaderTitle startDate={subscription.start_date} />
          <DetailCard
            onDetailClick={!isBusinessUser && onDetailCardClick}
            subscription={subscription}
            savings={savings}
            trackClick={trackClick}
            isBusinessUser={isBusinessUser}
          />
        </Header>

        <Content>
          <NewsMessageWrapper
            title={newsMessage?.title}
            description={newsMessage?.description}
            showMessage={!!newsMessage}
          />
          <InitialSectionTitle>
            <Typography token="text-title-medium" color="text-color-primary">
              Tu experiencia Plus
            </Typography>
          </InitialSectionTitle>
          <CardContainerWrapper withShadow={false} backgroundImage={savings ? savingVector : null}>
            {savings && (
              <>
                <SavingCard savings={savings} currencySymbol={subscription.currency_symbol} />
                <Divider />
              </>
            )}
            <InitialSectionSubtitle>
              <Typography token="text-title-small" color="text-color-primary">
                {getInitialSectionSubtitle()}
              </Typography>
            </InitialSectionSubtitle>
            <Benefits
              benefits={benefits}
              onConditionsClick={({ type }) =>
                trackClick(
                  `${type.toLowerCase()}_${TRACKING_CLICK_LOCATIONS.TERMS_AND_CONDITIONS}`,
                  `benefit.${TRACKING_CLICK_LOCATIONS.TERMS_AND_CONDITIONS}`,
                )
              }
            />
          </CardContainerWrapper>
        </Content>
        {!isBusinessUser && (
          <>
            <Divider />
            <Tyc
              terms_and_conditions={terms_and_conditions}
              onTermAndConditionClick={() =>
                trackClick(TRACKING_CLICK_LOCATIONS.TERMS_AND_CONDITIONS)
              }
            />
          </>
        )}
      </section>

      {pop_up && pop_up?.content && (
        <Popup
          id="status-popup"
          content={pop_up?.content}
          delayTime={pop_up?.delay_time}
          showOneTime
          theme="light"
        />
      )}

      {!isBusinessUser && (
        <ReactivateSubscription
          open={isOpenReactivateSubs}
          onClose={onCloseReactivateSubscription}
          subscription={status.subscription}
          onSubscriptionUpdate={onSubscriptionUpdate}
          trackClick={trackClick}
        />
      )}

      <Feedback stateValue="cancelFeedback" />
    </>
  );
};

SubscriptionStatus.propTypes = {};
