import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import Spinner from '@app/components/Spinner';
import { BrandError } from '@app/components/Error';
import { fetchCancellation } from '@app/services/loyalty/fetchCancellation';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { getHomeLink } from '@commons/utils/deeplinks';
import { IMAGE_URL_DOMAIN } from '@commons/constants/imagesDomain';
import { Header } from './Header';
import { DetailCard } from './DetailCard';
import { Footer } from './Footer';
import HeaderShowcase from '@pedidosya/web-fenix/system/HeaderShowcase';
import messages from '../../../messages';

export const Unsubscribe = ({ onGoBack, onContinue }) => {
  const cancellationQuery = useQuery(queryKeyBuilders.cancellation(), fetchCancellation);
  const isLoading = cancellationQuery.isLoading;
  const isError = cancellationQuery.isError;
  const { deviceInfo } = usePublicEnv();
  const backgroundImage = `${IMAGE_URL_DOMAIN}/image/pedidosya/loyalty/loyalty-pedidosya-header.png`;

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return (
      <BrandError
        message={<FormattedMessage {...messages.brandError} />}
        primaryActionLabel="Intentar nuevamente"
        primaryActionClick={cancellationQuery.refetch}
        secondaryActionLabel="Ir al inicio"
        secondaryActionClick={() => {
          window.location = getHomeLink(deviceInfo);
        }}
      />
    );
  }

  return (
    <>
      <Header onGoBack={onGoBack} backgroundImage={backgroundImage} />
      <HeaderShowcase
        backgroundImage={backgroundImage}
        text3={cancellationQuery.data.header.subtitle}
        title={cancellationQuery.data.header.title}
        variant="inverted"
      />
      <DetailCard
        orders={cancellationQuery.data.orders}
        savings={cancellationQuery.data.savings}
        monthlyFee={cancellationQuery.data.monthly_fee}
        benefitsSummary={cancellationQuery.data.benefits_summary}
        currencySymbol={cancellationQuery.data.currency_symbol}
      />
      <Footer onClose={onGoBack} onCancel={onContinue} />
    </>
  );
};

Unsubscribe.propTypes = {
  onGoBack: PropTypes.func,
  onContinue: PropTypes.func,
};
