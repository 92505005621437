import React from 'react';
import PropTypes from 'prop-types';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';
import BrandError from '@pedidosya/web-fenix/pages/Error';
import logger from '@app/logger';
import FeedbackPage from '@app/components/Revamp/FeedbackPage';

export const ErrorBoundary = ({ error, ...props }) => {
  if (error?.__sentry_captured__) {
    // Allow the hub to re-catch the error if the main sentry instance already caught it
    delete error.__sentry_captured__;
  }

  const isRevamp = props?.children?.props?.children?.props?.context?.isRevamp;

  // TODO: Use fatal
  logger.error(error);

  if (isRevamp)
    return (
      <FenixProvider config={{ revamp: isRevamp }}>
        <FeedbackPage
          showClose={false}
          showBack={false}
          title="Lo sentimos, tuvimos un problema"
          description="Ya estamos trabajando para resolverlo, volvé a intentarlo en unos minutos."
        />
      </FenixProvider>
    );

  return (
    <FenixProvider config={{ revamp: isRevamp }}>
      <BrandError {...props} />
    </FenixProvider>
  );
};

ErrorBoundary.propTypes = {
  error: PropTypes.instanceOf(Error),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
