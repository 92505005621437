import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { formatCurrency } from '@app/utils/format/currency';
import PriceboxFull from '@pedidosya/web-fenix/patterns/PriceboxFull';
import ThumbnailStacked from '@pedidosya/web-fenix/system/ThumbnailStacked';
import ThumbnailImage from '@pedidosya/web-fenix/system/ThumbnailImage';
import ThumbnailLabel from '@pedidosya/web-fenix/system/ThumbnailLabel';
import RowInformativeM from '@pedidosya/web-fenix/system/RowInformativeM';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import { Container } from './styled-components';

const MAX_VENDORS = 3;

export const DetailCard = ({ orders, savings, monthlyFee, benefitsSummary, currencySymbol }) => {
  const intl = useIntl();

  const extraVendors = orders?.vendors?.length - MAX_VENDORS ?? 0;
  const vendors = extraVendors > 0 ? orders?.vendors?.slice(0, MAX_VENDORS - 1) : orders?.vendors;

  const savingsElements = savings && [
    ...savings.categories.map((category, _) => ({
      amount: formatCurrency(intl, { amount: category.amount, currencySymbol }),
      primaryText: category.title,
      children: (
        <RowInformativeM.Asset>
          <FenixIcon color="default" size="medium" token={category.icon} />
        </RowInformativeM.Asset>
      ),
    })),
    {
      amount: formatCurrency(intl, {
        amount: savings.subtotal.amount || savings.subtotal,
        currencySymbol,
      }),
      primaryText: savings.subtotal.title || 'Subtotal',
      primaryTextType: 'highlight',
      amountType: 'highlight',
    },
    {
      amount: formatCurrency(intl, { amount: -monthlyFee.amount, currencySymbol }),
      primaryText: monthlyFee.title,
    },
  ];

  const benefitsElements = benefitsSummary && [
    ...benefitsSummary.benefits.map((benefit, idx) => ({
      primaryText: benefit.title,
      children: (
        <RowInformativeM.Asset>
          <FenixIcon color="default" size="medium" token={benefit.icon} />
        </RowInformativeM.Asset>
      ),
    })),
  ];

  const vendorsElements = orders && (
    <ThumbnailStacked>
      {vendors.map((vendor, index) => (
        <ThumbnailImage key={index} shape="square" size="XS" src={vendor.img} stroke />
      ))}
      {extraVendors > 0 && (
        <>
          <ThumbnailLabel size="XS" variant="neutral" label={`+ ${extraVendors + 1}`} stroke />
        </>
      )}
    </ThumbnailStacked>
  );

  return (
    <Container>
      <PriceboxFull
        priceRows={savingsElements || benefitsElements}
        priceboxTitleVolanta={savings ? null : orders?.title}
        priceboxTitleVolantaIcon={null}
        priceboxTitle={orders && savings ? orders?.title : benefitsSummary.title}
        priceboxTitleRightElement={vendorsElements}
        total={
          savings &&
          formatCurrency(intl, {
            amount: savings.total.amount || savings.total,
            currencySymbol,
          })
        }
      />
    </Container>
  );
};

DetailCard.propTypes = {
  orders: PropTypes.object,
  savings: PropTypes.object,
  monthlyFee: PropTypes.object,
  benefitsSummary: PropTypes.object,
  currencySymbol: PropTypes.string,
};
