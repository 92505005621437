import React from 'react';
import PropTypes from 'prop-types';
import TopAppBar from '@pedidosya/web-fenix/system/TopAppBar';
import { useScroll } from '@app/hooks/useScroll';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import HeaderIOS from '@app/components/Revamp/HeaderIOS';
import styled, { useTheme } from 'styled-components';

const TopAppBarContainer = styled.div`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;

  > nav {
    ${({ isWebviewIos, scrolled, theme }) =>
      isWebviewIos &&
      scrolled &&
      `
        @media ${theme.mediaQueries.mobileIos} {
        top: ${theme.space('spacing-10')};
      }

      @media ${theme.mediaQueries.mobileIosNotch} {
        top: ${theme.space('spacing-20')};
      }
    `}
  }
`;

export const Header = ({ onGoBack, backgroundImage }) => {
  const theme = useTheme();
  const isWebviewIos = useIsWebviewIos();
  const [scrolled] = useScroll(false);

  const backgroundColor = theme.color('shape-color-background-primary');

  return (
    <TopAppBarContainer
      scrolled={scrolled}
      isWebviewIos={isWebviewIos}
      backgroundImage={backgroundImage}
    >
      <HeaderIOS {...(scrolled && { backgroundColor: backgroundColor, isSticky: true })} />
      <TopAppBar
        left="icon"
        onGoBack={onGoBack}
        variant="inverted"
        position={!scrolled ? 'sticky' : 'fixed'}
        {...(scrolled && {
          middle: 'title',
          titleValue: 'Cancelar suscripción',
        })}
      />
    </TopAppBarContainer>
  );
};

Header.propTypes = {
  onGoBack: PropTypes.func,
};
