import { useMutation } from '@tanstack/react-query';
import { fetchStatusBill } from '@app/services/loyalty/fetchStatusBill';

export const useDownloadBill = () => {
  const fetchStatusBillMutation = useMutation(fetchStatusBill);

  const downloadBill = async ({ billId, planId, preventShare = false }) => {
    try {
      const blob = await fetchStatusBillMutation.mutateAsync({ billId, planId });
      const filename = `${billId}.pdf`;
      const file = new File([blob], filename, { type: 'application/pdf' });
      const shareData = { title: filename, files: [file] };

      if (!preventShare && navigator.canShare && navigator.canShare(shareData)) {
        await navigator.share(shareData);
      } else {
        const URL = window.URL || window.webkitURL;
        const blobUrl = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.style = 'display: none';
        anchor.href = blobUrl;
        anchor.download = filename;
        anchor.rel = 'noopener';
        anchor.click();
        URL.revokeObjectURL(blobUrl);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return { downloadBill };
};
