import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { sendPaymentConfigurationStrategy } from '@app/services/loyalty/sendPaymentConfigurationStrategy';
import { sendEvent } from '@app/services/loyalty/sendEvent';
import CardIcon from '@pedidosya/web-fenix/icons/CardIcon';
import CUSTOM_EVENTS from '@commons/constants/customEvents';
import BoxInteractive from '@pedidosya/web-fenix/system/BoxInteractive';
import ThumbnailIcon from '@pedidosya/web-fenix/system/ThumbnailIcon';
import Snackbar from '@pedidosya/web-fenix/system/Snackbar';

const AlternatePaymentCard = ({
  planId,
  checked,
  onChangeMultiplePaymentCardClick,
  onChangePaymentConfig,
}) => {
  const [selected, setSelected] = useState(checked);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const { mutate, isLoading } = useMutation(sendPaymentConfigurationStrategy);

  const handleClick = () => {
    setSelected(!selected);
    if (isLoading) return;

    changePaymentConfig();
  };

  const changePaymentConfig = () => {
    onChangeMultiplePaymentCardClick();
    sendEvent(CUSTOM_EVENTS.SUBSCRIPTION_MANAGEMENT_CLICKED_CHANGE_MULTIPLE_PAYMENT_CARD);

    const value = !selected;
    setSelected(value);
    mutate(
      { planId, strategy: value ? 'ALL' : 'ORIGINAL' },
      {
        onSuccess: () => {
          onChangePaymentConfig();
        },
        onError: (err) => {
          onError(selected);
          logger.error(err);
        },
      },
    );
  };

  const onError = (selected) => {
    setOpen(true);
    setMessage(
      `No pudimos ${
        selected ? 'deshabilitar' : 'habilitar'
      } el uso de tus tarjetas, vuelve a intentar.`,
    );
    setSelected(selected);
  };

  return (
    <>
      <BoxInteractive
        type="activable"
        primaryTextType="title"
        primaryText="Usar tarjetas guardadas"
        secondaryText="Cobrar en cualquiera de mis tarjetas cuando falle el pago."
        onClick={handleClick}
        rightElement={{
          selected,
        }}
        leftElement={
          <ThumbnailIcon size="M" variant="neutral">
            <CardIcon />
          </ThumbnailIcon>
        }
      />
      {open && (
        <Snackbar
          label={message}
          onClickAction={() => setOpen(false)}
          onClose={() => setOpen(false)}
          show={open}
          type="error"
        />
      )}
    </>
  );
};

export default AlternatePaymentCard;
