import React, { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { useDeviceInfo } from '@app/contexts/deviceInfo';
import { fetchStatus } from '@app/services/loyalty/fetchStatus';
import { fetchStatusDetail } from '@app/services/loyalty/fetchStatusDetail';
import { fetchSubscription } from '@app/services/loyalty/fetchSubscription';
import { useHeaderConfiguration } from '@app/hooks/useHeaderConfiguration';
import FeedbackPage from '@app/components/Revamp/FeedbackPage';
import { getHomeLink } from '@commons/utils/deeplinks';
import { BrandError } from '../../components/Error';
import Spinner from '../../components/Spinner';
import { SubscriptionStatus } from './components/SubscriptionStatus';
import messages from './messages';
import { useStatusPageTracker } from './tracking/StatusPageTracker';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { useFlagVariation } from '@app/contexts/FwfFlags/hooks';
import { BOOLEAN_FLAGS } from '@commons/fwf/constants';

const clearHistory = () => window?.HistoryInterface?.clearHistory();

export const StatusPage = () => {
  const deviceInfo = useDeviceInfo();
  const statusQuery = useQuery(queryKeyBuilders.status(), fetchStatus);
  const intl = useIntl();

  const queryClient = useQueryClient();
  const isLoading = statusQuery.isLoading;
  const isError = statusQuery.isError;

  const { isRevamp } = usePublicEnv();
  const revampFlag = useFlagVariation(BOOLEAN_FLAGS.WEB_LOYALTY_PROGRAM_REVAMP.KEY);

  const { trackClick } = useStatusPageTracker({ isErrorPage: isError, isPageReady: !isLoading });

  useHeaderConfiguration({ isError });

  const redirectToDetailsPage = () => {
    const url = `/loyalty-program/status-details`;
    window.singleSpaNavigate(url);
  };

  const updateData = () => {
    statusQuery.refetch();
    queryClient.invalidateQueries({
      queryKey: queryKeyBuilders.statusDetail(),
    });
    queryClient.prefetchQuery({
      queryKey: queryKeyBuilders.statusDetail(),
      queryFn: fetchStatusDetail,
    });
    queryClient.invalidateQueries({
      queryKey: queryKeyBuilders.subscription(),
    });
    queryClient.prefetchQuery({
      queryKey: queryKeyBuilders.subscription(),
      queryFn: fetchSubscription,
    });
  };

  const onSendSubscriptionChangeEvent = () => {
    import('@pedidosya/web-native-bridges/eventbus').then((eventbus) => {
      eventbus.publish('plusSubscriptionChanged', {});
    });
  };

  const onSubscriptionUpdate = () => {
    updateData();
    onSendSubscriptionChangeEvent();
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: queryKeyBuilders.statusDetail(),
    });
    queryClient.prefetchQuery({
      queryKey: queryKeyBuilders.statusDetail(),
      queryFn: fetchStatusDetail,
    });
  }, [queryClient]);

  useEffect(() => {
    clearHistory();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    if (revampFlag && isRevamp) {
      return (
        <FeedbackPage
          onFirstCTAClick={statusQuery.refetch}
          firstCTALabel={intl.formatMessage(messages.revampFeedbackPageErrorButtonLabel)}
          backAction={() => (window.location = getHomeLink(deviceInfo))}
          showClose={false}
          title={intl.formatMessage(messages.revampFeedbackPageErrorTitle)}
          description={intl.formatMessage(messages.revampFeedbackPageErrorDescription)}
        />
      );
    }

    return (
      <BrandError
        message={<FormattedMessage {...messages.brandError} />}
        primaryActionLabel="Intentar nuevamente"
        primaryActionClick={statusQuery.refetch}
        secondaryActionLabel="Ir al inicio"
        secondaryActionClick={() => {
          window.location = getHomeLink(deviceInfo);
        }}
      />
    );
  }

  return (
    <>
      <SubscriptionStatus
        status={statusQuery.data}
        redirectToStatusPage={redirectToDetailsPage}
        trackClick={trackClick}
        onSubscriptionUpdate={onSubscriptionUpdate}
      />
    </>
  );
};
