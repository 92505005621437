import React from 'react';

import BottomSheet from '@pedidosya/web-fenix/system/BottomSheet';

function BenefitBottomSheet({ setShowCondition, showCondition, children }) {
  return (
    <BottomSheet
      handler={false}
      onClickOut={() => {
        setShowCondition(!showCondition);
      }}
      onClose={() => {
        setShowCondition(!showCondition);
      }}
      open={showCondition}
      scrollEnabled
      title="Condiciones"
      variant="modal"
    >
      <div>
        <React.Fragment key=".$0">
          {children}
          <br />
        </React.Fragment>
      </div>
    </BottomSheet>
  );
}

export default BenefitBottomSheet;
