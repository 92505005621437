import styled from 'styled-components';

export const TextBlock = styled.div`
  display: inline-block;
  flex-direction: column;
  text-align: start;
  padding: ${({ theme }) =>
    `${theme.space('spacing-layout-large')} ${theme.space('layout-margin-mobile')} ${theme.space(
      'spacing-layout-4xlarge',
    )} ${theme.space('layout-margin-mobile')}`};
`;

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.space('spacing-layout-large')};
  margin-bottom: ${({ theme }) => theme.space('spacing-layout-4xlarge')};
`;
