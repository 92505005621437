import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';
import FenixBottomSheet from '@pedidosya/web-fenix/system/BottomSheet';
import Typography from '@pedidosya/web-fenix/foundations/Typography';
import Skeleton from './Skeleton';
import { Container } from './styled-components';

function TermsAndConditions({ isLoading, data, open, onClose }) {
  const sanitizedData = useMemo(
    () => DOMPurify.sanitize(data, { RETURN_DOM: true }).innerHTML,
    [data],
  );

  return (
    <>
      <FenixBottomSheet
        handler={false}
        open={open}
        onClickOut={onClose}
        onClose={onClose}
        scrollEnabled={true}
        title={<div aria-label="tyc-bottomsheet">Términos y condiciones</div>}
        variant="modal"
      >
        <Container>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Typography token="text-base-medium">
              <div dangerouslySetInnerHTML={{ __html: sanitizedData }} />
            </Typography>
          )}
        </Container>
      </FenixBottomSheet>
    </>
  );
}

TermsAndConditions.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TermsAndConditions;
