import * as React from 'react';
import RowInteractiveL from '@pedidosya/web-fenix/system/RowInteractiveL';
import RowInformativeL from '@pedidosya/web-fenix/system/RowInformativeL';
import ButtonIcon from '@pedidosya/web-fenix/system/ButtonIcon';
import ChevronRight from '@pedidosya/web-fenix/icons/ChevronRight';
import BenefitDetailIcon from '@app/components/Revamp/BenefitDetailIcon';
import BenefitBottomSheet from '../BenefitBottomSheet';
import BenefitConditions from '@app/components/Revamp/BenefitConditions';

function Benefit({ benefit, onConditionsClick }) {
  const { conditions, title, description, type } = benefit;
  const [showCondition, setShowCondition] = React.useState(false);

  const openConditions = () => {
    if (conditions) {
      onConditionsClick();
      setShowCondition(true);
    }
  };

  return (
    <>
      {conditions ? (
        <RowInteractiveL
          primaryLeftText={title}
          secondaryLeftText={description}
          primaryLeftTextType="highlight"
          ctaType="navigable"
          onClickRow={() => conditions && openConditions()}
        >
          <RowInteractiveL.LeftElementAsset>
            <BenefitDetailIcon type={type} />
          </RowInteractiveL.LeftElementAsset>

          {conditions && (
            <RowInteractiveL.RightElementCTA>
              <ButtonIcon
                icon={<ChevronRight />}
                state="enabled"
                size="small"
                buttonStyle="default"
              />
              <BenefitBottomSheet showCondition={showCondition} setShowCondition={setShowCondition}>
                <BenefitConditions {...conditions} />
              </BenefitBottomSheet>
            </RowInteractiveL.RightElementCTA>
          )}
        </RowInteractiveL>
      ) : (
        <RowInformativeL
          primaryLeftText={title}
          secondaryLeftText={description}
          primaryLeftTextType="highlight"
        >
          <BenefitDetailIcon type={type} />
        </RowInformativeL>
      )}
    </>
  );
}

export default Benefit;
