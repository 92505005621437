import styled from 'styled-components';
import Button from '@pedidosya/web-fenix/system/Button';

export const ButtonLinkContainer = styled.div`
  display: inline-block;

  > div {
    height: fit-content;
  }
`;

export const ButtonLinkWrapper = styled(Button)`
  padding: 0;

  &:focus {
    outline: none;
  }
`;
