import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { hasPercentage, hasFreeTrial } from '@commons/utils/markdowns';
import { DISCOUNT_TYPE } from '@commons/models/discountTypeEnum';
import {
  PlanDetailContent,
  PlanPriceContainer,
  PlanContainer,
  PriceContainer,
} from './styled-components';
import { Price } from '@app/components/PlanPrice';
import { PlanTitle } from '@app/components/PlanTitle';
import messages from '../../../status/messages';

const getMonthText = (stringDate) => {
  const date = new Date(stringDate);
  return date.toLocaleString('es-ES', { month: 'long' });
};

const getFullYear = (stringDate) => {
  const date = new Date(stringDate);
  return date.getFullYear();
};

const getDiscountText = (amount, discountType) => {
  switch (discountType) {
    case DISCOUNT_TYPE.PERCENTAGE:
      return `${amount}%`;
    default:
      amount;
  }
};

export const PlanDetail = ({ subscription, purchase }) => {
  const { start_date, currency_symbol, monthly_fee } = subscription;
  const { markdowns } = purchase;
  const [first] = markdowns || [];
  const { discount_amount, discount_type, campaign_period_valid_to, monthly_fee_after_markdown } =
    first || {};

  const intl = useIntl();
  const freeTrialMarkdown = hasFreeTrial(markdowns);
  const percentageMarkdowns = hasPercentage(markdowns);

  const getTitle = () => {
    const discount = percentageMarkdowns
      ? intl.formatMessage(messages.planDiscountTitle, {
          discountAmountText: getDiscountText(Math.floor(discount_amount), discount_type),
        })
      : null;

    return (
      <PlanTitle
        title={
          <Typography token="font-body-midcontrast-sentence-large" color="text-color-primary">
            <FormattedMessage {...messages.planTitle} />
          </Typography>
        }
        discount={freeTrialMarkdown ? 'Gratis' : discount}
      />
    );
  };

  const getSubtitle = () => {
    return markdowns
      ? intl.formatMessage(messages.promotionSubtitle, {
          date: intl.formatDate(campaign_period_valid_to),
        })
      : intl.formatMessage(messages.planSubtitle, {
          month: getMonthText(start_date),
          year: getFullYear(start_date),
        });
  };

  const getAmountTitle = () => {
    const actualAmount = markdowns ? monthly_fee_after_markdown : monthly_fee;
    const beforeAmount = markdowns ? monthly_fee : null;
    return (
      <Price symbol={currency_symbol} actualAmount={actualAmount} beforeAmount={beforeAmount} />
    );
  };

  return (
    <PlanDetailContent>
      <PlanPriceContainer>
        <PlanContainer>
          {getTitle()}
          <Typography token="font-label-midcontrast-medium" color="text-color-tertiary">
            {getSubtitle()}
          </Typography>
        </PlanContainer>
        <PriceContainer>{getAmountTitle()}</PriceContainer>
      </PlanPriceContainer>
    </PlanDetailContent>
  );
};

PlanDetail.propTypes = {
  subscription: PropTypes.shape({
    next_charge_date: PropTypes.string,
    status: PropTypes.string,
    start_date: PropTypes.string,
    currency_symbol: PropTypes.string,
  }),
  purchase: PropTypes.shape({
    markdowns: PropTypes.array,
    amount: PropTypes.number,
  }),
};
