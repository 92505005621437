import { defineMessages } from 'react-intl';

export default defineMessages({
  brandError: {
    id: 'app.brandError.genericError',
    defaultMessage: 'No pudimos llevarte a donde esperabas.',
  },
  cancelModalErrorTitle: {
    id: 'app.status.cancelModalErrorTitle',
    defaultMessage: 'No pudimos cancelar tu suscripción',
  },
  cancelModalErrorMessage: {
    id: 'app.status.cancelModalErrorMessage',
    defaultMessage: 'Intenta nuevamente en unos minutos.',
  },
  cancelSubscriptionPositiveMessage: {
    id: 'app.status.cancelSubscriptionPositiveMessage',
    defaultMessage: 'Tu suscripción fue cancelada.',
  },
  cancelSuscriptionQueryErrorTitle: {
    id: 'app.status.cancelSuscriptionQueryErrorTitle',
    defaultMessage: 'Lo sentimos, tuvimos un problema',
  },
  cancelSuscriptionQueryErrorDescription: {
    id: 'app.status.cancelSuscriptionQueryErrorDescription',
    defaultMessage: 'Ya estamos trabajando para resolverlo, volvé a intentarlo en unos minutos.',
  },
  cancelSuscriptionQueryErrorButtonLabel: {
    id: 'app.status.cancelSuscriptionQueryErrorButtonLabel',
    defaultMessage: 'Volver a intentar',
  },
});
