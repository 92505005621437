import React from 'react';
import FenixDisclaimer from '@pedidosya/web-fenix/system/Disclaimer';
import { useIntl } from 'react-intl';
import messages from '@app/pages/status/messages';

function Disclaimer({ savings }) {
  const intl = useIntl();

  const formatedText = `En ${intl.formatMessage(messages.savingOrders, {
    orders: savings?.orders_count,
  })} ya ahorraste`;

  return <FenixDisclaimer primaryText={formatedText} icon={null} />;
}

export default Disclaimer;
