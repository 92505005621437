import { useMutation } from '@tanstack/react-query';
import { sendBillMail } from '@app/services/loyalty/sendBillMail';

export const useSendBill = (onError) => {
  const mutation = useMutation(sendBillMail);

  const sendBill = ({ billId, planId, amount, date }) => {
    mutation.mutate(
      { billId, planId, amount, date },
      {
        onError: (err) => {
          console.error(err);
          if (onError) onError();
        },
      },
    );
  };

  return { sendBill };
};
