import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { BILL_STATUS } from '@commons/models/billStatusEnum';
import { formatCurrency } from '@app/utils/format/currency';
import { useIsWebviewAndroid, useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import { fetchStatusPayments } from '@app/services/loyalty/fetchStatusPayments';
import Snackbar from '@pedidosya/web-fenix/system/Snackbar';
import BottomSheet from '@pedidosya/web-fenix/system/BottomSheet';
import RowInformativeM from '@pedidosya/web-fenix/system/RowInformativeM';
import RowInteractiveL from '@pedidosya/web-fenix/system/RowInteractiveL';
import List from '@pedidosya/web-fenix/system/List';
import Button from '@pedidosya/web-fenix/system/Button';
import Skeleton from './Skeleton';
import { useDownloadBill } from './hooks/useDownloadBill';
import { useSendBill } from './hooks/useSendBill';
import messages from '../../../messages';

export const Payments = ({ open, onClose, planId }) => {
  const isWebviewAndroid = useIsWebviewAndroid();
  const isWebviewIos = useIsWebviewIos();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const intl = useIntl();
  const { isLoading, data } = useQuery(
    queryKeyBuilders.statusPayments(planId),
    () => fetchStatusPayments(planId),
    { enabled: open },
  );
  const { downloadBill } = useDownloadBill();
  const { sendBill } = useSendBill(() => setShowSnackbar(true));

  const getDocument = (payment) => {
    if (payment.bill.status !== BILL_STATUS.AVAILABLE) return;

    if (isWebviewAndroid)
      sendBill({ billId: payment.bill.id, planId, amount: payment.amount, date: payment.date });
    else if (isWebviewIos) downloadBill({ billId: payment.bill.id, planId });
    else downloadBill({ billId: payment.bill.id, planId, preventShare: true });
  };

  const getBillText = () => {
    if (isWebviewAndroid) return intl.formatMessage(messages.paymentsSendBill);
    return intl.formatMessage(messages.paymentsDownloadBill);
  };

  return (
    <BottomSheet
      handler
      scrollEnabled
      variant="modal"
      open={open}
      title={intl.formatMessage(messages.paymentsTitle)}
      onClickOut={onClose}
      onClose={onClose}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <RowInformativeM
            primaryText={intl.formatMessage(
              isWebviewAndroid ? messages.paymentsSubtitleSend : messages.paymentsSubtitle,
            )}
          />
          <List collapseRows={20} type="default">
            {data &&
              data.items &&
              data.items.map((payment, idx) => {
                return (
                  <List.Row>
                    <RowInteractiveL
                      label={`Fecha: ${intl.formatDate(payment.date)}`}
                      primaryLeftText={`Monto: ${formatCurrency(intl, {
                        amount: payment.amount,
                        currencySymbol: payment.currencySymbol,
                      })}`}
                      ctaType="navigable"
                      onClickRow={() => getDocument(payment)}
                    >
                      <RowInteractiveL.RightElementCTA>
                        <Button label={getBillText()} hierarchy="tertiary" size="small" />
                      </RowInteractiveL.RightElementCTA>
                    </RowInteractiveL>
                  </List.Row>
                );
              })}
          </List>
          <Snackbar
            label={<FormattedMessage {...messages.paymentsSendError} />}
            type="error"
            show={showSnackbar}
            onClose={() => setShowSnackbar(false)}
          />
        </>
      )}
    </BottomSheet>
  );
};

Payments.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  planId: PropTypes.number,
};
