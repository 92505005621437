import * as React from 'react';

import FenixHeaderShowcase from '@pedidosya/web-fenix/system/HeaderShowcase';

import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../../messages';
import { getFullYear, getMonthText } from '@commons/utils/date/date';
import { STATUS_PAGE_HEADER_IMAGE } from '@commons/constants/images';

function HeaderShowcase({ subscription }) {
  const intl = useIntl();

  return (
    <FenixHeaderShowcase
      backgroundColor="shape-color-surface-secondary"
      imageUrl={STATUS_PAGE_HEADER_IMAGE}
      text3={
        <FormattedMessage
          {...messages.headerSubtitle}
          values={{
            month: getMonthText(subscription.start_date),
            year: getFullYear(subscription.start_date),
          }}
        />
      }
      title={intl.formatMessage(messages.headerTitle)}
      variant="inverted"
    />
  );
}

export default HeaderShowcase;
