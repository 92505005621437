import styled from 'styled-components';

export const HeaderIOS = styled.div`
  width: 100%;

  ${({ isWebviewIos, theme }) =>
    /* The following values are hardcoded as they don't depend on fenix tokens */
    isWebviewIos &&
    `
    @media ${theme.mediaQueries.mobileIos} {
      padding-top: 20px;
    }

    @media ${theme.mediaQueries.mobileIosNotch} {
      padding-top: 40px;
    }
  `}
`;

export const Container = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.space('spacing-layout-xlarge')};
`;
